import './assets/css/main.scss'
import './assets/css/styleguide.css'
import "primevue/resources/themes/lara-light-indigo/theme.css"
import "primevue/resources/primevue.min.css"
import "primeicons/primeicons.css"

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import cookies from 'js-cookie'
import VueGtag from "vue-gtag"
import axios from 'axios';
import VueAxios from 'vue-axios'

import VueMeta from 'vue-meta'
import VueSocialauth from 'vue-social-auth'
import PrimeVue from 'primevue/config';
import Password from 'primevue/password';

Vue.use(VueMeta)

Vue.use(VueAxios, axios)

Vue.use(VueSocialauth, {
  providers: {
    google: {
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      redirectUri: process.env.VUE_APP_GOOGLE_REDIRECT_URL
    },
    linkedin: {
      clientId: process.env.VUE_APP_LINKEDIN_CLIENT_ID,
      redirectUri: process.env.VUE_APP_LINKEDIN_REDIRECT_URL,
      scope: 'profile+email+openid'
    }
  }
})

Vue.use(VueGtag, {
  config: { id: "G-530GJF74Y6" }
});

window.axios = require('axios')
require('./plugins');

window.io = require('socket.io-client')

import { mixins } from './mixins';
Vue.mixin(mixins);

Vue.config.productionTip = false

import DefaultLayout from './layouts/DefaultLayout.vue'
import AuthLayout from './layouts/AuthLayout.vue'

Vue.component('default-layout', DefaultLayout)
Vue.component('auth-layout', AuthLayout)
Vue.component('Password', Password);

Vue.use(PrimeVue);

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.state.token) {
    if ( from.name == 'Login' ) {
      return next({name:'Login'});
    } else {
      const token = cookies.get('x-access-token')
      if (token) {
        const twoFaRequired = cookies.get('2fa-required')
        if (twoFaRequired && twoFaRequired == 'true') {
          return next({name:'Login'});
        } else { 
          store.dispatch('setTokenFromCookie', { token })
        }
      } else {
        return next({name:'Login'});
      }
    }
    
  }
  return next();
});

Vue.filter('formatDate', function(value) {
  if (value) {
    const date = new Date(value);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options); // Змініть 'en-US' на потрібний вам код мови
  }
  return '';
});

Vue.filter('numberFormat', function (value) {
  if(value){
    const stringValue = value.toString();
    const [integerPart, decimalPart] = stringValue.split('.');
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    
    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
  }
  return '';
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
