<template>
  <div class="page">
    <div class="auth-form__wrapper">
      <form class="auth-form" id="login-form">
        <div class="auth-form__logo-wrapper">
          <a :href="getLandingUrl" class="auth-form__logo">
            <img src="/img/logo.svg" alt="Logo">
          </a>
        </div>
        <h2 class="form-title">Sign In Your Account</h2>
        <div class="details">
          <div class="login-with__social">
            <p class="login-with__social-text">Enter a verification code</p>
          </div>
            
          <div class="input-group">
            <input class="form-control" type="text" v-model="code" placeholder="Two factor code">
            <span class="invalid-feedback" v-if="codeError.length > 0">{{ codeError }}</span>
          </div>
          
          
          <div class="auth-form__btn">
            <button v-if="!load" class="btn wide submit" @click.prevent="login">Login</button>
            <div v-else class="load"><CircleLoad /></div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CircleLoad from '@/components/img/CircleLoad'

export default {
  name: "Login",
  components: {
    CircleLoad
  },
  data() {
    return {
      load: false,
      code: '',
      codeError: ''
    }
  },
  computed: {
    getLandingUrl () {
      return process.env.VUE_APP_LANDING_URL
    }
  },
  methods: {
    async login() {
      this.load = true
      const that = this
      this.codeError = ''
      axios.defaults.headers.common.Authorization = 'Bearer ' + this.$store.state.token
      await axios.post(process.env.VUE_APP_BACKEND_API_URL + 'user/two-factor-code', {
          two_factor_code: this.code,
        })
        .then(function (response) {
          if (response.data.error) {
            for (let key in response.data.errors) {
              const error = response.data.errors[key][0]
              if (key === 'two_factor_code' && error) {
                if (error == 'Code expired') {
                  that.$noty.warning('Your two factor code expired')
                  that.$store.dispatch('logout')
                  that.$router.replace({ name: "Login" })
                } else {
                  that.codeError = error
                }
              }
            }
            return
          }
          that.$store.dispatch('removeTwoFactorOnLogin')
          that.$router.replace({ name: "YouOffers" })
        })
        .catch(function (error) {
          console.log(error);
        })
      this.load = false
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  position: relative;
  z-index: 1;
  &:before{
    content: "";
    z-index:-1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url('/img/nero.svg');
    background-repeat: no-repeat;
  }
  .auth-form__logo{
    max-width: 120px;
    &-wrapper{
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
  .auth-form__wrapper{
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: row;
    gap: 32px;
    justify-content: center;
    align-items: center;
  }
  .auth-form__link{
    color: #1763fb;
    margin-left: 4px;
  }
  .auth-form__new-account{
    font-family: var(--placeholder-field-font-family);
    font-weight: var(--placeholder-field-font-weight);
    color: var(--on-surface);
    font-size: var(--placeholder-field-font-size);
    letter-spacing: var(--placeholder-field-letter-spacing);
    line-height: var(--placeholder-field-line-height);
    white-space: nowrap;
    font-style: var(--placeholder-field-font-style);
  }
  .auth-form, .details{
    width: 480px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px
  }
  .details{
    gap: 24px;
  }
  .form-title {
    font-family: var(--title-bold-1-font-family);
    font-weight: var(--title-bold-1-font-weight);
    color: var(--on-surface);
    font-size: var(--title-bold-1-font-size);
    letter-spacing: var(--title-bold-1-letter-spacing);
    line-height: var(--title-bold-1-line-height);
    white-space: nowrap;
    font-style: var(--title-bold-1-font-style);
  }
  .login-with__social{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;
    &-btns{
      width: 100%;
    }
    &-text{
      font-family: var(--placeholder-field-font-family);
      font-weight: var(--placeholder-field-font-weight);
      color: var(--on-surface);
      font-size: var(--placeholder-field-font-size);
      letter-spacing: var(--placeholder-field-letter-spacing);
      line-height: var(--placeholder-field-line-height);
      white-space: nowrap;
      font-style: var(--placeholder-field-font-style);
    }
  }
  .input-group{
    width: 100%;
    input{
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 16px 24px !important;
      position: relative;
      align-self: stretch;
      width: 100%;
      flex: 0 0 auto;
      border-radius: 5px;
      border: 1px solid;
      border-color: #ababb2;
      margin-bottom: 0 !important;
    }
    &.password{
      position: relative;
      img{
        position: absolute;
        right: 25px;
        top: 17px;
      }
    }
  }
  .btn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 16px 24px;
    position: relative;
    align-self: stretch;
    flex: 0 0 auto;
    border-radius: 5px;
    border: 1px solid;
    border-color: #09090b;
    color: #09090b;
    font-family: var(--for-button-font-family);
    font-weight: var(--for-button-font-weight);
    font-size: var(--for-button-font-size);
    letter-spacing: var(--for-button-letter-spacing);
    line-height: var(--for-button-line-height);
    white-space: nowrap;
    font-style: var(--for-button-font-style);
    &.wide{
      width: 100%;
    }
    &.submit{
      background-color: #1763fb;
      border-color: #1763fb;
      color: #fff
    }
  }
  .auth-form__forgot{
    display: flex;
    justify-content: end;
    width: 100%;
  }
  .auth-form__btn{
    display: flex;
    width: 100%;
  }
  .forgot-password{
    margin-left: auto;
    position: relative;
    width: fit-content;
    font-family: var(--placeholder-field-font-family);
    font-weight: var(--placeholder-field-font-weight);
    color: #3177ff;
    font-size: var(--placeholder-field-font-size);
    letter-spacing: var(--placeholder-field-letter-spacing);
    line-height: var(--placeholder-field-line-height);
    white-space: nowrap;
    font-style: var(--placeholder-field-font-style);
  }
  .invalid-feedback{
    color: red;
  }
}
</style>
