<template>
  <div class="page">
    <div class="auth-form__wrapper">
      <form class="auth-form" id="login-form">
        <div class="auth-form__logo-wrapper">
          <a :href="getLandingUrl" class="auth-form__logo">
            <img src="/img/logo.svg" alt="Logo">
          </a>
        </div>
        <h2 class="form-title">Sign In Your Account</h2>
        <div class="details">
          <div class="login-with__social">
            <p class="login-with__social-text">Use daily base profile for fast communication</p>
            <div class="login-with__social-btns">
              <a href="#" class="login-with__google btn wide btn-with__icon" @click="authProvider('google')">
                <img class="img" src="/img/google.svg" />
                Google
              </a>
            </div>
            <div class="login-with__social-btns">
              <a href="#" class="login-with__linkedin btn wide btn-with__icon" @click="authProvider('linkedin')">
                <img class="img" src="/img/linkedin.png" />
                LinkedIn
              </a>
            </div>
            <p class="login-with__social-text">or sign up with E-mail</p>
          </div>
          <div class="input-group">
            <input class="form-control wide" type="text" v-model="email" placeholder="Email">
            <span class="invalid-feedback" v-if="emailError.length > 0">{{ emailError }}</span>
          </div>
          <div class="input-group password">
            <input class="form-control wide" :type="showPassword ? 'text' : 'password'" v-model="password" placeholder="Password">
            <img class="vuesax-linear-eye" src="/img/vuesax-linear-eye-slash-1.svg" @click.prevent="showPassword = !showPassword" />
            <span class="invalid-feedback" v-if="passwordError.length > 0">{{ passwordError }}</span>
          </div>
          <div class="auth-form__forgot">
            <a class="forgot-password" @click.prevent="goToForgotPassword">
              Forgot your password?
            </a>
          </div>
          <div class="auth-form__btn">
            <button v-if="!load" class="btn wide submit" @click.prevent="login">Login</button>
            <div v-else class="load"><CircleLoad /></div>
          </div>
          <p class="auth-form__new-account">
            <span class="span">Don’t have an account?</span>
            <span>&nbsp;</span>
            <span class="auth-form__link" @click.prevent="goToSignUp">Sing up</span>
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CircleLoad from '@/components/img/CircleLoad'

export default {
  name: "Login",
  components: {
    CircleLoad
  },
  data() {
    return {
      load: false,
      email: '',
      password: '',
      emailError: '',
      passwordError: '',
      twoFactorOnLogin: false,
      showPassword: false,
      partner_link: null
    }
  },
  mounted() {
    const action = this.$route.query.action
    this.partner_link = this.$route.query.partner_link

    if(action === 'google') {
      this.authProvider('google')
    }
    
    if(action === 'linkedin') {
      this.authProvider('linkedin')
    }
  },
  computed: {
    getLandingUrl () {
      return process.env.VUE_APP_LANDING_URL
    }
  },
  methods: {
    async get2faSettings () {
      const that = this
      await axios.get(process.env.VUE_APP_BACKEND_API_URL + 'settings/customer-two-factor-on-login')
        .then(function ({ data }) {
          that.twoFactorOnLogin = (+data.customer_two_factor_on_login) ? true : false;
          const twoFactorOnLogin = that.twoFactorOnLogin
          that.$store.dispatch('setTwoFactorOnLogin', { twoFactorOnLogin });
          if (twoFactorOnLogin && data.code_is_not_empty) {
            that.$noty.success('Please, check your email - we send you two factor code.')
            that.$router.replace({ name: "LoginCode" })
          } else {
            that.$store.dispatch('removeTwoFactorOnLogin')
            that.$router.replace({ name: "YouOffers" })
          } 
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    async login() {
      this.load = true
      const that = this
      await axios.post(process.env.VUE_APP_BACKEND_API_URL + 'auth/login', {
          email: this.email,
          password: this.password,
        })
        .then(function (response) {
          if (response.data.error) {
            for (let key in response.data.errors) {
              if (key === 'email' && response.data.errors[key][0]) {
                that.emailError = response.data.errors[key][0]  
              } else if (key === 'password' && response.data.errors[key][0]) {
                that.passwordError = response.data.errors[key][0]
              }
            }
            return
          }
          const { token, expiresIn } = response.data
          that.$store.dispatch('setToken', { token, expiresIn })
          that.$store.dispatch('setUser')
          that.$store.dispatch('setContent')
          that.get2faSettings() 
        })
        .catch(function (error) {
          console.log(error);
        })
      this.load = false
    },
    goToForgotPassword () {
      this.$router.push({ name: 'ForgotPassword' })
    },
    goToSignUp () {
      this.$router.push({ 
        name: 'Register',
        params: { partner_link: this.partner_link }
      });
    },
    async authProvider(provider) {
      this.load = true
      var self = this
      await this.$auth.authenticate(provider).then(response => {
        self.socialLogin(provider, response)
        }).catch(err => {
          console.log({err:err})
      })
    },
    async socialLogin(provider, response){
      const that = this
      await this.$http.post(process.env.VUE_APP_BACKEND_API_URL + 'auth/sociallogin/' + provider, {code: response.code}).then(response => {
        const { token, expiresIn } = response.data
        that.$store.dispatch('setToken', { token, expiresIn })
        that.$store.dispatch('setUser')
        that.$store.dispatch('setContent')
        that.get2faSettings()
      }).catch(err => {
        console.log({err:err})
      })
      this.load = false
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  position: relative;
  z-index: 1;
  &:before{
    content: "";
    z-index:-1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url('/img/nero.svg');
    background-repeat: no-repeat;
  }
  .auth-form__logo{
    max-width: 120px;
    &-wrapper{
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
  .auth-form__wrapper{
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: row;
    gap: 32px;
    justify-content: center;
    align-items: center;
  }
  .auth-form__link{
    color: #1763fb;
    margin-left: 4px;
  }
  .auth-form__new-account{
    font-family: var(--placeholder-field-font-family);
    font-weight: var(--placeholder-field-font-weight);
    color: var(--on-surface);
    font-size: var(--placeholder-field-font-size);
    letter-spacing: var(--placeholder-field-letter-spacing);
    line-height: var(--placeholder-field-line-height);
    white-space: nowrap;
    font-style: var(--placeholder-field-font-style);
  }
  .auth-form, .details{
    width: 480px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px
  }
  .details{
    gap: 24px;
  }
  .form-title {
    font-family: var(--title-bold-1-font-family);
    font-weight: var(--title-bold-1-font-weight);
    color: var(--on-surface);
    font-size: var(--title-bold-1-font-size);
    letter-spacing: var(--title-bold-1-letter-spacing);
    line-height: var(--title-bold-1-line-height);
    white-space: nowrap;
    font-style: var(--title-bold-1-font-style);
  }
  .login-with__social{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;
    &-btns{
      width: 100%;
    }
    &-text{
      font-family: var(--placeholder-field-font-family);
      font-weight: var(--placeholder-field-font-weight);
      color: var(--on-surface);
      font-size: var(--placeholder-field-font-size);
      letter-spacing: var(--placeholder-field-letter-spacing);
      line-height: var(--placeholder-field-line-height);
      white-space: nowrap;
      font-style: var(--placeholder-field-font-style);
    }
  }
  .input-group{
    width: 100%;
    input{
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 16px 24px !important;
      position: relative;
      align-self: stretch;
      width: 100%;
      flex: 0 0 auto;
      border-radius: 5px;
      border: 1px solid;
      border-color: #ababb2;
      margin-bottom: 0 !important;
    }
    &.password{
      position: relative;
      img{
        position: absolute;
        right: 25px;
        top: 17px;
      }
    }
  }
  .btn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 16px 24px;
    position: relative;
    align-self: stretch;
    flex: 0 0 auto;
    border-radius: 5px;
    border: 1px solid;
    border-color: #09090b;
    color: #09090b;
    font-family: var(--for-button-font-family);
    font-weight: var(--for-button-font-weight);
    font-size: var(--for-button-font-size);
    letter-spacing: var(--for-button-letter-spacing);
    line-height: var(--for-button-line-height);
    white-space: nowrap;
    font-style: var(--for-button-font-style);
    &.wide{
      width: 100%;
    }
    &.submit{
      background-color: #1763fb;
      border-color: #1763fb;
      color: #fff
    }
  }
  .auth-form__forgot{
    display: flex;
    justify-content: end;
    width: 100%;
  }
  .auth-form__btn{
    display: flex;
    width: 100%;
  }
  .forgot-password{
    margin-left: auto;
    position: relative;
    width: fit-content;
    font-family: var(--placeholder-field-font-family);
    font-weight: var(--placeholder-field-font-weight);
    color: #3177ff;
    font-size: var(--placeholder-field-font-size);
    letter-spacing: var(--placeholder-field-letter-spacing);
    line-height: var(--placeholder-field-line-height);
    white-space: nowrap;
    font-style: var(--placeholder-field-font-style);
  }
  .invalid-feedback{
    color: red;
  }
  .login-with__linkedin{
    img{
      width: 24px;
      height: 24px;
    }
  }
}
</style>
